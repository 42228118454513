<template>
  <genericModalLogic
    title="Share Collection"
    class="shareCollectionModal"
    v-if="isModalVisible"
    @closeModal="close"
  >
    <div class="shareCollectionContent bookFont">
      <addPeopleInputGeneric
        :validateEmails="validateEmails"
        :failedEmails="failedEmails"
        :userLogged="store.state.dataUserLogin.email"
        @listUpdated="updateEmails($event)"
        @emailsValidated="shareCollection($event)"
        ref="peopleInputRef">
      </addPeopleInputGeneric>
      <section class="shareCollectionContent__buttons">
        <div class="shareCollectionContent__buttons__box--share">
          <button
            class="shareCollectionContent__buttons__box--link"
            :data-clipboard-text="shareLink"
            @click="doCopy"
          >
            <span class="icon-grey-link" />
            <span class="copyLinkLabel">Copy link</span>
          </button>
        </div>
        <div class="shareCollectionContent__buttons__box">
          <span class="shareCollectionContent__buttons__box--cancel" @click.stop="close">CANCEL</span>
          <span class="shareCollectionContent__buttons__box--send" @click.stop="handleClick">SEND</span>
        </div>
      </section>
    </div>
  </genericModalLogic>
  <toast
    v-if="linkToast"
    :message="messages.shareCollectionMessages.linkToast">
  </toast>
  <toast
    v-if="shareToast"
    :message="messages.shareCollectionMessages.shareToast">
  </toast>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import genericModalLogic from '../genericModalLogic/genericModalLogic'
import messagesShareCollection from './shareCollection.json'
import { copyText } from 'vue3-clipboard'

export default {
  name: 'shareCollection',
  components: {
    genericModalLogic
  },
  props: {
    collectionId: {
      type: String
    }
  },
  setup (props) {
    const store = useStore()
    const isModalVisible = ref(false)
    const emails = ref([])
    const shareLink = ref('')
    const linkToast = ref(null)
    const shareToast = ref(null)
    const messages = messagesShareCollection
    const failedEmails = ref([])
    const validateEmails = ref(false)
    const peopleInputRef = ref(null)

    /**
     * @description Share collection
     * @param validation String to know if emails validation were successfull
     */
    function shareCollection (validation) {
      validateEmails.value = false
      if (validation === 'success') {
        store.dispatch({
          type: 'collections/shareCollectionByEmail',
          collectionId: props.collectionId,
          emailList: emails.value
        }).then(
          (response) => {
            failedEmails.value = response.data.failedEmails
            if (failedEmails.value.length > 0) {
              peopleInputRef.value.setError(`${failedEmails.value.length === 1 ? 'single' : 'multiple'}Error`, failedEmails.value.length === 1 ? failedEmails.value[0] : '')
            } else {
              showShareToast()
              close()
            }
          },
          (error) => {
            console.error(error)
          }
        )
      }
    }

    /**
     * @description Handle click event
     */
    function handleClick () {
      validateEmails.value = true
    }

    /**
     * @description Updates list of emails
     * @param emailsList Array of emails
     */
    function updateEmails (emailsList) {
      emails.value = emailsList
    }

    /**
     * @description Set clipboard event for share link button
     */
    function setupCopy () {
      store.dispatch({
        type: 'collections/getCollectionShareLink',
        collectionId: props.collectionId
      }).then(
        (response) => {
          shareLink.value = response.data.response
        }
      )
    }

    /**
     * @description Invoke library method in order to copy collection link on clipboard.
     */
    function doCopy () {
      copyText(shareLink.value, undefined, (error, event) => {
        if (error) {
          alert('Can not copy')
          return
        }
        showLinkToast()
      })
    }

    /**
     * @description Show toast notifying that the link was copied for 5 seconds
     */
    function showLinkToast () {
      if (!linkToast.value) {
        linkToast.value = true
        setTimeout(() => {
          linkToast.value = false
        }, 5000)
      }
    }

    /**
     * @description Show toast notifying that the collection was succesfully shared
     */
    function showShareToast () {
      if (!shareToast.value) {
        shareToast.value = true
        setTimeout(() => {
          shareToast.value = false
        }, 5000)
      }
    }

    /**
     * @description Opens the modal.
     * @param itemId id of item to delete
     */
    function open () {
      isModalVisible.value = true
      setupCopy()
    }

    /**
     * @description Closes the modal.
     * @param event of DOM.
     */
    function close (event) {
      emails.value = []
      window.event.preventDefault()
      isModalVisible.value = false
    }

    return {
      open,
      close,
      isModalVisible,
      messages,
      linkToast,
      shareCollection,
      shareLink,
      shareToast,
      doCopy,
      store,
      updateEmails,
      handleClick,
      validateEmails,
      peopleInputRef,
      failedEmails
    }
  }
}
</script>
