<template>
  <section class="containerGenericModalLogic" :class="theme ? `containerGenericModalLogic--${theme}` : ''" @click="closeModal">
    <div class="containerGenericModalLogic__content" id="containerGenericModalLogic__content--id" @click.stop.prevent="cancelEvent">
      <header class="containerGenericModalLogic__content--header">
        <span :class="icon" class="icon"></span>
        <span class="title" :title="tooltipTitle" style="-webkit-box-orient: vertical;">{{title}}</span>
        <span class="closeBtn grey-icon-close" @click.stop="closeModal($event)"></span>
      </header>
      <section class="containerGenericModalLogic__content--body">
        <slot></slot>
      </section>
    </div>
  </section>
</template>

<script>

export default {
  name: 'addCollectionsModal',
  props: {
    title: {
      type: String,
      required: true
    },
    tooltipTitle: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    theme: {
      type: String,
      required: false,
      default: null,
      validator: function (value) {
        return [null, 'gray'].indexOf(value) !== -1
      }
    }
  },
  emits: ['closeModal'],
  setup (props, { emit }) {
    /**
     * @description Dispatch emit when user close modal.
     * @param {event} event got from the DOM.
     */
    function closeModal (event) {
      emit('closeModal', event)
    }

    /**
     * @description Captaures any click got inside the modal.
     * @param {event} event got from the DOM.
     */
    function cancelEvent (event) {
      event.preventDefault()
    }

    return {
      closeModal,
      cancelEvent
    }
  }
}
</script>
