<template>
  <genericModalLogic
    title="Delete Collection"
    class="deleteCollectionModal"
    v-if="isModalVisible"
    @closeModal="close"
  >
    <p class="deleteCollectionModal__message">
      <span class="deleteCollectionModal__message--message">
        {{messageBody}}
      </span>
    </p>
    <section class="deleteCollectionModal__buttons">
      <button class="deleteCollectionModal__buttons--cancelButton" @click.stop="close" type="button">CANCEL</button>
      <button class="deleteCollectionModal__buttons--deletedButton" type="button" @click.stop="deleteCollection">DELETE</button>
    </section>
  </genericModalLogic>
</template>

<script>
import { computed, ref } from 'vue'
import genericModalLogic from '../genericModalLogic/genericModalLogic'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'deleteCollection',
  components: {
    genericModalLogic
  },
  props: {
    collectionId: {
      type: String,
      required: false,
      default: ''
    },
    itemCollectionId: {
      type: String,
      required: false,
      defaul: ''
    },
    isMine: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['setup-page-again'],
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const isModalVisible = ref(false)

    /**
     * @description Select a message body to show depending the flow active
     */
    const messageBody = computed(() => {
      return props.itemCollectionId ? 'Are you sure you want to delete this item?' : 'Are you sure you want to delete this Collection?'
    })

    /**
     * @description Opens the modal.
     * @param itemId id of item to delete
     */
    function open () {
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param event of DOM.
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    /**
     * @description Dispatch actions depending if a collection will be deleted or
     * if an item will be removed from collection
     */
    function deleteCollection () {
      if (props.itemCollectionId) {
        store.dispatch({
          type: 'collections/deleteItemCollectionById',
          collectionId: props.collectionId,
          itemCollectionId: props.itemCollectionId
        }).then(
          (response) => {
            emit('setup-page-again')
          }
        )
      } else {
        store.dispatch({
          type: 'collections/deleteCollectionById',
          collectionId: props.collectionId
        }).then(
          (response) => {
            if (route.name === 'collections') emit('setup-page-again')
            else window.location = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/collections?s=${props.isMine ? 'mine' : 'shared'}`
          }
        )
      }
    }

    return {
      open,
      close,
      isModalVisible,
      deleteCollection,
      messageBody,
      store
    }
  }
}
</script>
