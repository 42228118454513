<template>
  <section class="containerCollectionOptions">
    <template v-if="!isTablet" >
      <template v-for="(action) in actionsByType" :key="action">
        <span
          class="containerCollectionOptions__icon" :class="action.icon"
          :title="action.title"
          @click="emitAction(`${action.action}`)"
          v-if="showOptionsByDefault(action)"
        >
        </span>
      </template>
    </template>
    <template v-else>
      <span class="containerCollectionOptions__showMore grey-icon-show-more"
        title="Edit"
        @click="toggleIconsVisibility">
      </span>
      <section class="containerCollectionOptions__optionsModal" v-if="showDefaultActions" v-click-away="toggleIconsVisibility">
        <template v-for="(action) in actionsByType" :key="action">
          <p
            v-if="showOptionsByDefault(action)"
            class="containerCollectionOptions__optionsModal--label"
            @click="emitAction(`${action.action}`)">
            <span
              class="containerCollectionOptions__optionsModal--label-icon" :class="action.icon"
              title="Edit this Collection">
            </span>
            {{action.label}}
          </p>
        </template>
      </section>
    </template>
    <deleteCollection
      ref="deleteCollectionModalRef"
      :collectionId="collectionId"
      @setup-page-again="$emit('setup-page-again')"
      :isMine="isMine"
    >
    </deleteCollection>
    <shareCollection
      ref="shareCollectionModalRef"
      :collectionId="collectionId"
    >
    </shareCollection>
    <createCollectionModalGeneric
      v-if="showCreateModal"
      :collectionId="collectionId"
      :action="modalCollectionAction"
      :name="name"
      :isPublic="isPublic"
      :coOwners="coOwners"
      :isMine="isMine"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="showCreateModal = false"
      @close="showCreateModal = false"
      @reload-collections="$emit('setup-page-again')"
    ></createCollectionModalGeneric>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import configuration from './collectionOptions.json'
import deleteCollection from '../../components/deleteCollection/deleteCollection'
import shareCollection from '../../components/shareCollection/shareCollection'

export default {
  name: 'collectionOptions',
  components: {
    deleteCollection,
    shareCollection
  },
  props: {
    isEmpty: {
      type: Number
    },
    isPublic: {
      type: Boolean
    },
    isMine: {
      type: Boolean
    },
    hasPeopleItems: {
      type: Boolean
    },
    collectionId: {
      type: String
    },
    name: {
      type: String
    },
    showMoreResolution: {
      type: Number,
      default: 1024
    },
    coOwners: {
      type: Number,
      default: 0
    },
    isCoOwner: {
      type: Boolean,
      default: false
    }
  },
  emits: ['setup-page-again'],
  setup (props, { emit }) {
    const store = useStore()
    const showDefaultActions = ref(false)
    const actionsByType = ref({})
    const deleteCollectionModalRef = ref(null)
    const shareCollectionModalRef = ref(null)
    const createCollectionModalRef = ref(null)
    const modalCollectionAction = ref('create')
    const showCreateModal = ref(false)
    const isTablet = ref(false)

    onMounted(() => {
      resizeSetUp()
      handleResize()
      generateActionsMenu(props.hasPeopleItems)
    })

    /**
     * @description Validate if there are items in order to show or not some options
     * @param {action} Action to make once icon being pressed
     */
    const showOptionsByDefault = computed(() => (action) => {
      return props.isEmpty > 0 || !action.hideIfEmpty
    })

    /**
     * @description Filter the actions acording parameters
     * @param hasPeopleItemsFlag flag indicating if the collection has people items
     */
    function generateActionsMenu (hasPeopleItemsFlag) {
      const type = hasPeopleItemsFlag ? 'withPeopleItems' : 'withoutPeopleItems'
      const actionsByTypeTemp = configuration.actionsAllowedByType[type][props.isPublic ? 'public' : 'private'][props.isMine || props.isCoOwner ? 'owner' : 'noOwner']
      actionsByType.value = actionsByTypeTemp.reduce((actions, item) => {
        actions.push(configuration.actions.filter(allActions => allActions.name === item)[0])
        return actions
      }, [])
    }

    /**
     * @description Toggles visibility of default actions.
     */
    function toggleIconsVisibility () {
      showDefaultActions.value = !showDefaultActions.value
    }

    /**
     * @description Emit the action specified
     * @param {action} action to be emited
     */
    function emitAction (action) {
      switch (action) {
        case 'toggle-delete-modal':
          deleteCollectionModalRef.value.open()
          break
        case 'download-collection-items':
          downloadCollectionAsCsv()
          break
        case 'send-email':
          sendEmail()
          break
        case 'toggle-share-modal':
          shareCollectionModalRef.value.open()
          break
        case 'toggle-duplicate-modal':
          modalCollectionAction.value = 'duplicate'
          showCreateModal.value = true
          break
        case 'toggle-edit-modal':
          modalCollectionAction.value = 'edit'
          showCreateModal.value = true
          break
      }
    }

    /**
     * @description Dispatch action in order to download collection as a CSV file
     */
    function downloadCollectionAsCsv () {
      store.dispatch({
        type: 'collections/getCollectionItemsAsCsv',
        collectionId: props.collectionId
      })
    }

    /**
     * @description Open a default email client with a list of emails
     */
    function sendEmail () {
      store.dispatch({
        type: 'collections/getCollectionMembers',
        collectionId: props.collectionId
      })
    }

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Checks if device is tablet
    */
    function resizeSetUp () {
      if (window.innerWidth <= props.showMoreResolution) isTablet.value = true
      else isTablet.value = false
    }

    return {
      store,
      showDefaultActions,
      actionsByType,
      emitAction,
      showOptionsByDefault,
      deleteCollectionModalRef,
      shareCollectionModalRef,
      createCollectionModalRef,
      modalCollectionAction,
      showCreateModal,
      toggleIconsVisibility,
      isTablet
    }
  }
}
</script>
